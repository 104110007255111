import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../../components/Layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import GalleryContainer from '../../components/GalleryContainer'
import GalleryImage from '../../components/GalleryImage'
import { AboutContact, VideoContainer } from '..'
import { Content } from '../../components/styles'
import Button from '../../components/Button'
import { Video } from '../../components/procedures'

export default ({ data }) => (
  <Layout header="dark">
    <Helmet>
      <title>Gable Carport | Just Patios</title>
      <meta
        name="description"
        content="Just Patios are brisbanes professional gable carport builders. Custom gable carports available for Sunshine Coast, Brisbane, and Caboolture regions. Contact us today for your free design & quote."
      />
    </Helmet>

    <Content style={{ paddingTop: 150 }}>
      <h1>Gable Carport</h1>

      <h4>
        The perfect design to pair with your existing roof-line and home
        colours.
      </h4>
      <p>
        A Gable Carport can certainly add character and most importantly
        compliment your existing roof line, allowing it to seamlessly blend with
        your home. The Gable Carport range comes in 3 popular styles which are:
        traditional gable ends, hip ends, and dutch gable ends. These can all be
        customised to your home and needs. Styles are available in the full
        Colourbond and Colourbond equivalent range to marry in with your
        existing home colours.
      </p>

      <Link to="/contact-us#form">
        <Button inverted={true}>
          Request Your Free Gable Carport Quote Today!
        </Button>
      </Link>
    </Content>

    <GalleryContainer>
      {data.gallery.edges
        .sort((x, y) => {
          if (
            x.node.childImageSharp === null ||
            y.node.childImageSharp === null
          ) {
            return 0
          }
          const arr = x.node.childImageSharp.fixed.src.split('/')
          const number = parseInt(arr[arr.length - 1].split('.')[0])
          const arr2 = y.node.childImageSharp.fixed.src.split('/')
          const number2 = parseInt(arr2[arr2.length - 1].split('.')[0])

          return number < number2 ? -1 : 1
        })
        .map((image, i) => {
          if (image.node.childImageSharp === null) {
            return false
          }
          const arr = image.node.childImageSharp.fixed.src.split('/')
          const name = arr[arr.length - 1].split('.')[0]
          if (name === 'cover') {
            return null
          }
          return (
            <GalleryImage modal={image.node.childImageSharp.modal}>
              <Img fixed={image.node.childImageSharp.fixed} />
            </GalleryImage>
          )
        })}
    </GalleryContainer>

    <VideoContainer style={{ marginTop: -100 }}>
      <Video
        videoId="yqTGp7gMtng"
        opts={{
          height: '576',
          width: '1024',
          playerVars: {
            color: 'white',
            rel: 0,
          },
        }}
      />
    </VideoContainer>

    <AboutContact style={{ margin: '0 auto', padding: 0 }} />
  </Layout>
)

export const query = graphql`
  query GableCarportQuery {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "gallery/carports/gable" } }
    ) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
